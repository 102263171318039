import { useCallback } from 'react';
import { useCarrefourOIDC } from './useCarrefourOIDC';
import { matchEmailDomain } from '../../common/utils/auth.utils';

interface UseOIDCResponse {
  startOIDC: (email: string) => { matched: boolean };
}

export const SUPPORTED_DOMAINS = ['carrefour.com'];

export function useOIDC(): UseOIDCResponse {
  const { startProcess: startCarrefourProcess } = useCarrefourOIDC();

  const startOIDC = useCallback((email: string) => {
    const matchingDomain = SUPPORTED_DOMAINS.find((domain) => matchEmailDomain(email, domain));
    let matched = true;

    switch (matchingDomain) {
      case 'carrefour.com':
        startCarrefourProcess(email);
        break;
      default:
        matched = false;
    }

    return {
      matched,
    };
  }, []);

  return {
    startOIDC,
  };
}

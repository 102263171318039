import { useCallback } from 'react';
import { generateStateAndNonce } from '../../common/utils/auth.utils';

const CARREFOUR_SCOPES = ['openid', 'profile', 'email'];
const CARREFOUR_TYPE_FLOW = ['code', 'token', 'id_token'];
const DOMAIN = 'carrefour.com';

interface UseCarrefourOIDCResponse {
  startProcess: (email: string) => void;
}

export function useCarrefourOIDC(): UseCarrefourOIDCResponse {
  const startProcess = useCallback(async (email: string) => {
    const { state, nonce } = generateStateAndNonce(email, DOMAIN);

    const urlParams = new URLSearchParams({
      response_type: CARREFOUR_TYPE_FLOW.join(' '),
      redirect_uri: `${window.location.origin}/callback`,
      client_id: import.meta.env.REACT_APP_CARREFOUR_CLIENT_ID,
      scope: CARREFOUR_SCOPES.join(' '),
      state,
      nonce,
    });

    const url = `${import.meta.env.REACT_APP_CARREFOUR_SSO_URL}?${urlParams.toString()}`;

    window.location.assign(url);
  }, []);

  return {
    startProcess,
  };
}

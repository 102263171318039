import { jwtDecode } from 'jwt-decode';

interface AuthState {
  email: string;
  domain: string;
  key: string;
}

interface GenerateStateAndNonceResponce {
  state: string;
  nonce: string;
}

const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

/**
 * Determine if email has exact matching of the given domain
 * or it matches a subdomain of the given domain.
 */
export function matchEmailDomain(email: string, domain: string): boolean {
  const emailDomain = email.split('@')[1];

  return domain === emailDomain || new RegExp(`^.+?\\.${domain}$`).test(emailDomain);
}

/**
 * Create a base 64 encoded string of the SSO state based on a random string,
 * the email and the domain of the user.
 */
export function generateSSOStateString(email: string, domain: string): string {
  const key = Math.random().toString(36).substring(2, 10);

  const state = {
    email,
    domain,
    key,
  };

  return btoa(JSON.stringify(state));
}

/**
 * Generate a random cryptographic string that should be added to any SSO auth process
 * to prevent replay attacks.
 */
export function generateNonce(): string {
  const buffer = new Uint8Array(12);
  const nonce: string[] = [];

  window.crypto.getRandomValues(buffer);

  buffer.forEach((char) => {
    const index = char % charset.length;

    nonce.push(charset[index]);
  });

  return nonce.join('');
}

export function generateStateAndNonce(email: string, domain: string): GenerateStateAndNonceResponce {
  const state = generateSSOStateString(email, domain);
  const nonce = generateNonce();

  localStorage.setItem('ssoAuthState', state);
  localStorage.setItem('ssoAuthNonce', nonce);

  return {
    state,
    nonce,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function validateStateFormat(state: any): state is AuthState {
  const validatedFormat = 'email' in state && 'domain' in state && 'key' in state;

  if (!validatedFormat) {
    return false;
  }

  const localStateString = localStorage.getItem('ssoAuthState');

  if (!localStateString) {
    return false;
  }

  const localState = JSON.parse(atob(localStateString));

  return Object.entries(state).every(([key, value]) => localState[key] === value);
}

export function decodeState(state: string): AuthState | undefined {
  const authState = JSON.parse(atob(state));

  if (validateStateFormat(authState)) {
    return authState;
  }

  return undefined;
}

export function verifyNonce(access_token: string, nonce: string): boolean {
  const payload = jwtDecode(access_token);

  console.log('nonce', nonce);
  console.log('payload', payload);

  return !!payload;
}

import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useRedirectIfAuth } from '../hooks/useRedirectIfAuth';
import { PageWrapper } from '../common/components/PageWrapper';
import { decodeState, verifyNonce } from '../common/utils/auth.utils';
import useAuth from '../hooks/useAuth/useAuth';

const ENDPOINT_BY_DOMAIN: Record<string, string> = {
  'carrefour.com': `${import.meta.env.REACT_APP_SERVER_BASE_URL}/auth/login/carrefour`,
};

export default function OIDCCallbackPage(): React.JSX.Element {
  const RedirectIfAuth = useRedirectIfAuth();
  const { hash } = useLocation();
  const { setLoginToken } = useAuth();
  const [searchParams] = useSearchParams();

  const hashParams = useMemo(() => {
    const stringParams = hash.substring(1).split('&');

    return stringParams.reduce(
      (acc, stringParam) => {
        const [key, value] = stringParam.split('=');

        acc.set(key, value);

        return acc;
      },
      new Map(),
    );
  }, [hash]);

  // const authCode = searchParams.get('code') || hashParams.get('code');
  const authState = searchParams.get('state') || hashParams.get('state');
  const authToken = searchParams.get('access_token') || hashParams.get('access_token');
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    if (!authState || !authToken) {
      setError('L\'authentification a echoue, pas de token');

      return;
    }

    const state = decodeState(authState);

    if (!state) {
      setError(`L'authentification a echoue, mauvais state: ${JSON.stringify(authState)}`);

      return;
    }

    const nonce = localStorage.getItem('ssoAuthNonce');

    if (!verifyNonce(authToken, nonce!)) {
      setError('L\'authentification a echoue, mauvais nonce');

      return;
    }

    axios.post(
      ENDPOINT_BY_DOMAIN[state.domain]!,
      {
        access_token: authToken,
        nonce,
      },
    ).then((response) => {
      setLoginToken(response.data.tokens);
    });
  }, [authToken, authState]);

  return (
    <RedirectIfAuth>
      <PageWrapper>
        COUCOU
        {error}
      </PageWrapper>
    </RedirectIfAuth>
  );
}
